import { Box, Text } from '@butcherbox/freezer'
import React from 'react'
import { AddressCardProps } from './AddressCard.types'

export default function AddressCard({
  heading,
  address,
  showDeliveryInstructions = false,
}: AddressCardProps) {
  if (!address) {
    return null
  }
  return (
    <Box>
      {heading && <strong>{heading}</strong>}
      <Text data-dd-privacy="mask" variant="Body1Regular">
        {address.firstName} {address.lastName}
      </Text>
      <Text data-dd-privacy="mask" variant="Body1Regular">
        {address.addressLineOne}
      </Text>
      {address.addressLineTwo && (
        <Text data-dd-privacy="mask" variant="Body1Regular">
          {address.addressLineTwo}
        </Text>
      )}
      <Text data-dd-privacy="mask" variant="Body1Regular">
        {address.city}, {address.state} {address.postalCode}
      </Text>
      {showDeliveryInstructions && (
        <Box marginTop={12}>
          <strong>Delivery Notes</strong>
          <Text variant="Body1Regular">
            {address.deliveryInstructions ||
              "You don't have any delivery notes."}
          </Text>
        </Box>
      )}
    </Box>
  )
}
