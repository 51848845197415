import { Text } from '@butcherbox/freezer'
import { Box, BoxProps, Flex, VisuallyHidden } from '@chakra-ui/core'
import { RouteComponentProps, Router } from '@reach/router'
import dayjs from 'dayjs'
import { rem } from 'design'
import ProductCardGroup from 'design/components/CardProductGroup/CardProductGroup'
import LoadingSpinner from 'design/components/LoadingSpinner/LoadingSpinner'
import { LinkExternal } from 'design/components/Typography/Typography'
import React from 'react'
import AddressCard from '~/components/AddressCard/AddressCard'
import createDeliveryWindow from '~/components/Calendar/deliveryWindow'
import Collapse from '~/components/Collapse'
import ModalReportAnIssue from '~/components/ModalReportAnIssueFlow'
import Panel from '~/components/Panel/index'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { PanelSection } from '~/components/Panel/PanelSection/PanelSection'
import { PanelTable } from '~/components/Panel/PanelTable/PanelTable'
import { PanelTableRow } from '~/components/Panel/PanelTableRow/PanelTableRow'
import { TEST_ID } from '~/constants/cypress'
import { UserContext } from '~/context/user'
import useOrders from '~/hooks/useOrders'
import AccountLayout from '~/layouts/AccountLayout'
import { formatDeliveryWindow, formatPriceFromCents } from '~/utils'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'
import { humanizeOrderType, humanizeTrackingStatus } from '~/utils/humanize'
import { RouterPage } from '~/utils/route'
import ReportAnIssueButton from '../AccountHelp/ReportAnIssueButton'
import * as Styles from './AccountOrderHistory.css'

// Bring in self-service modal and make report issue conditional only for most recent order
function AccountOrderHistory({ navigate }: RouteComponentProps) {
  const user = React.useContext(UserContext)
  const [selectedOrder, setSelectedOrder] = React.useState(0)
  const { data: orders = [], status } = useOrders()

  const handleModalClose = () => navigate('./')

  const toggleSelectedOrder = (idx) => {
    if (idx === selectedOrder) {
      setSelectedOrder(-1)
    } else {
      setSelectedOrder(idx)
    }
  }
  // Rather than use isLoading boolean, represent request state as string
  // Detailed here: https://dev.to/davidkpiano/no-disabling-a-button-is-not-app-logic-598i
  switch (status) {
    case 'loading':
      return (
        <AccountLayout>
          <Box height={'100%'} textAlign={'center'}>
            <LoadingSpinner size={'regular'} />
          </Box>
        </AccountLayout>
      )
    case 'error':
      throw new Error('Failed to load account order history')
    case 'success':
      return (
        <AccountLayout>
          <VisuallyHidden>
            <h1>Order History</h1>
          </VisuallyHidden>
          <Box
            aria-label="Order history"
            as="ul"
            data-cy={TEST_ID.ACCOUNT_ORDER_HISTORY}
            listStyleType="none"
          >
            {orders.map(
              (
                {
                  box,
                  creditAmount,
                  datePaid,
                  discountAmount,
                  extras,
                  id,
                  invoiceItems,
                  invoiceItemsPrice,
                  reportable,
                  shippingPrice,
                  shipmentWeek,
                  taxAmount,
                  total,
                  trackingInformation,
                  type,
                  subtotal,
                  orderShipping,
                },
                idx
              ) => {
                const boxItems = duplicateItemsByQuantity(box.items)
                const dealItems = duplicateItemsByQuantity(invoiceItems)
                const addonItems = duplicateItemsByQuantity(box.addons)
                const offerItems = duplicateItemsByQuantity(box.offers)
                const extraItems = duplicateItemsByQuantity(extras)
                const regionId = `region-${idx}`

                return (
                  <Panel
                    aria-label={`${box.name}, ${
                      trackingInformation.status
                        ? humanizeTrackingStatus(trackingInformation.status)
                        : 'Processing'
                    }`}
                    as="li"
                    data-cy={TEST_ID.ORDER_HISTORY_PANEL}
                    data-cy-box-date={datePaid}
                    data-cy-order-total={(total / 100).toFixed(2)}
                    data-cy-reportable={reportable}
                    data-cy-tracking-info={trackingInformation.url}
                    key={id}
                    marginBottom={16}
                    paddingBottom={24}
                  >
                    <Flex as="h2" justifyContent="space-between">
                      <Text
                        component="span"
                        marginBottom={4}
                        variant="Subhead1"
                      >
                        {box.name}
                      </Text>
                      <Text component="span" variant="H4Bold">
                        {trackingInformation.status
                          ? humanizeTrackingStatus(trackingInformation.status)
                          : 'Processing'}
                      </Text>
                      <VisuallyHidden>
                        {dayjs(trackingInformation.deliveryDate).format(
                          'MMM D'
                        )}
                      </VisuallyHidden>
                    </Flex>
                    <Text color="stone" variant="Body1Regular">
                      {humanizeOrderType(type)}
                    </Text>
                    <Break />
                    <Box>
                      {trackingInformation.deliveryDate ? (
                        <Text variant="Body1Regular">
                          <strong>Delivery Date:</strong>{' '}
                          {dayjs(trackingInformation.deliveryDate).format(
                            'MMM D'
                          )}
                        </Text>
                      ) : shipmentWeek ? (
                        <Text variant="Body1Regular">
                          <strong>Estimated Delivery Window:</strong>{' '}
                          {formatDeliveryWindow(
                            createDeliveryWindow(shipmentWeek, user.shipZone)
                          )}
                        </Text>
                      ) : null}
                      <Text variant="Body1Regular">
                        <strong>Bill Date:</strong>
                        {' ' + dayjs(datePaid).format('MMM D')}
                      </Text>
                      <Text variant="Body1Regular">
                        <strong>Order Total:</strong> $
                        {(total / 100).toFixed(2)}
                      </Text>
                      {!!trackingInformation.url && (
                        <Text variant="Body1Regular">
                          <strong>Tracking Number:</strong>{' '}
                          <LinkExternal
                            // Put a space between each character in the tracking number for better VO readability:
                            // https://thatdevgirl.com/blog/accessibility-phone-number-formatting#aria-labels--a-more-reasonable-solution
                            aria-label={`${trackingInformation.number}`.replace(
                              /(.)(?=.)/g,
                              '$1 '
                            )}
                            data-cy={TEST_ID.ORDER_HISTORY_TRACKING_LINK}
                            href={trackingInformation.url}
                            title="Tracking details"
                          >
                            {trackingInformation.number}
                          </LinkExternal>
                        </Text>
                      )}
                      <AddressCard
                        address={orderShipping}
                        heading="Shipping Address:"
                      ></AddressCard>
                    </Box>

                    <Break className={Styles.PanelBreak} />

                    <Collapse
                      aria-labelledby={regionId}
                      buttonStyleProps={{
                        'aria-label': `${
                          selectedOrder === idx ? 'Hide' : 'Show'
                        } order details, delivery date ${
                          trackingInformation.deliveryDate
                            ? dayjs(trackingInformation.deliveryDate).format(
                                'MMMM D'
                              )
                            : 'unavailable'
                        }`,
                        'aria-expanded': selectedOrder === idx,
                        'aria-controls': regionId,
                        'data-cy': TEST_ID.EXPAND_DETAILS,
                        id: regionId,
                      }}
                      collapsedText="Show Order Details"
                      expandedText="Hide Order Details"
                      isOpen={selectedOrder === idx}
                      onClick={() => toggleSelectedOrder(idx)}
                      // @ts-ignore Chakra UI doesn't allow the role attribute on the Collapse component, but should.
                      role="region"
                    >
                      {reportable && (
                        <Text marginBottom={24} variant="Body2Regular">
                          Have a problem?{' '}
                          <ReportAnIssueButton
                            ftpId={orders[0].trackingInformation.ftpId}
                            variant="text"
                          />
                        </Text>
                      )}

                      {trackingInformation.status === 'processing' ? (
                        <Box mb={rem(32)} mt={rem(32)}>
                          Since your box is currently processing, we don’t have
                          anything to show you. Please check back once you get
                          your shipment notification to view the contents of
                          this order.
                        </Box>
                      ) : (
                        <>
                          {!!boxItems.length && (
                            <>
                              <PanelSection
                                content={box.name}
                                contentAddon={formatPriceFromCents(
                                  box.basePrice
                                )}
                                data-cy={TEST_ID.ORDER_HISTORY_BOX_TYPE}
                                title="Your subscription box type"
                              />

                              <PanelBreak marginBottom={16} />

                              <ProductCardGroup
                                aria-label={
                                  box.items.length
                                    ? `${box.name} box products included in your order`
                                    : ''
                                }
                                products={boxItems}
                                showFreeFlag={false}
                              />
                            </>
                          )}

                          {!!addonItems.length && (
                            <>
                              <PanelSection
                                content="Add-ons"
                                contentAddon={formatPriceFromCents(
                                  addonItems.reduce(
                                    (sum, addon) => sum + addon.price,
                                    0
                                  )
                                )}
                                marginTop={36}
                              />
                              <PanelBreak />

                              <ProductCardGroup
                                aria-label="Addon products included in your order"
                                products={addonItems}
                                showFreeFlag={false}
                              />
                            </>
                          )}

                          {!!dealItems.length && (
                            <>
                              <PanelSection
                                content="Member Deals"
                                contentAddon={formatPriceFromCents(
                                  invoiceItemsPrice
                                )}
                                marginTop={36}
                              />
                              <PanelBreak />

                              <ProductCardGroup
                                aria-label="Member deals included in your order"
                                products={dealItems}
                                showGroupedItems
                              />
                            </>
                          )}

                          {!!offerItems.length && (
                            <>
                              <PanelSection
                                content="Offers"
                                contentAddon="FREE"
                                marginTop={36}
                              />

                              <PanelBreak />

                              <ProductCardGroup
                                aria-label="Offers included in your order"
                                products={offerItems}
                              />
                            </>
                          )}

                          {!!extraItems.length && (
                            <>
                              <PanelSection
                                content="Extras"
                                contentAddon="FREE"
                                marginTop={36}
                              />

                              <PanelBreak />

                              <ProductCardGroup
                                aria-label="Free extra products included in your order"
                                products={extraItems}
                              />
                            </>
                          )}
                        </>
                      )}
                      <PanelTable>
                        <PanelTableRow
                          content="Subtotal"
                          contentAddon={`${formatPriceFromCents(subtotal)}`}
                          panelPaddingTop={rem(19)}
                        />

                        <PanelTableRow
                          content="Taxes"
                          contentAddon={`${formatPriceFromCents(taxAmount)}`}
                          panelPaddingTop={rem(19)}
                        />

                        <PanelTableRow
                          content="Shipping"
                          contentAddon={
                            shippingPrice === 0
                              ? 'FREE'
                              : `${formatPriceFromCents(shippingPrice)}`
                          }
                          panelPaddingTop={rem(16)}
                        />

                        {!!creditAmount && (
                          <PanelTableRow
                            content="Credits"
                            contentAddon={formatPriceFromCents(
                              -Math.abs(creditAmount)
                            )}
                            panelPaddingTop={rem(16)}
                          />
                        )}

                        {!!discountAmount && (
                          <PanelTableRow
                            content="Coupons"
                            contentAddon={formatPriceFromCents(
                              -Math.abs(discountAmount)
                            )}
                            panelPaddingTop={rem(16)}
                          />
                        )}
                      </PanelTable>

                      <PanelBreak marginBottom={20} marginTop={12} />

                      {/**
                       * Total
                       */}
                      <PanelTable caption="Cart total">
                        <PanelTableRow
                          content="Total"
                          contentAddon={`${formatPriceFromCents(total)}`}
                          data-cy={
                            TEST_ID.SECTION_ACCOUNT_ORDER_HISTORY_EXPANDED_TOTAL
                          }
                        />
                      </PanelTable>
                    </Collapse>
                  </Panel>
                )
              }
            )}

            <Router>
              <RouterPage
                path={`${REPORT_AN_ISSUE}/*`}
                renderRoute={(props) => (
                  <ModalReportAnIssue
                    handleModalClose={handleModalClose}
                    {...props}
                  />
                )}
              />
            </Router>
          </Box>
        </AccountLayout>
      )
  }
}

const Break: React.FC<BoxProps> = (props) => (
  <Box aria-hidden as="hr" borderColor="bb.silt" my={rem(16)} {...props} />
)

export default AccountOrderHistory

// URL CONSTANTS
const REPORT_AN_ISSUE = 'report-an-issue'
